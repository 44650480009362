import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, A11y } from "swiper";

import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";

import CmsSocial from "./CmsSocial";

import "swiper/css/bundle";
import "swiper/css/effect-fade";

import ImageWrapper from "./ImageWrapper";;
import { EffectFade } from "swiper";

import Link from "next/link";

function CmsSwiper({ component, page_id = "" }) {
  component.options.time = 5000;
  component.options.speed = 1000;

  const [swiper, setSwiper] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const dynamicRoute = useRouter().asPath;

  useEffect(() => {
    setIsClient(true);
  }, [dynamicRoute, swiper]);

    return (
      <div id={`${component.id ?? ""}`}>
        {isClient && (
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={0}
          autoplay={{
            delay: component.options.time,
            disableOnInteraction: false,
          }}
          speed={component.options.speed}
          autoHeight={false}
          // slidesPerView={component.options.slidesPerView}
          breakpoints={{
            768: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            991: {
              slidesPerView: component.options.slidesPerView,
              spaceBetween: 30
            },
          }}
          // navigation={true}
          loop={component.options.slides.length > 1 ? true : false}
          // pagination={{
          //   el: ".custom-pagination",
          //   clickable: true,
          // }}
          onSwiper={setSwiper}
        >
          {component.options.slides.map(function (slide, i) {
            return (
              <SwiperSlide key={i} className={``}>
                    {slide.image.url != "" ? (
                      <ImageWrapper
                        alt=""
                        src={slide.image.url}
                        blur={slide.image.urlp}
                        layout="intrinsic"
                        width={slide.image.width}
                        height={slide.image.height}
                        priority={i==0}
                      />
                    ) : (
                      ""
                    )}
                  <div
                    className="caption"
                    dangerouslySetInnerHTML={{ __html: slide.caption }}
                  />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      </div>
    );
  
}

export default CmsSwiper;
